import React, { useState } from "react"
import * as styles from "./work.module.scss"
import WORK from "./work.json"
import { GitHubLogo, PaperclipIcon } from "../icons"

const WorkPage = () => {
  const [focused, setFocus] = useState(false)

  const toggleFocus = src => {
    if (focused === false || focused !== src) {
      setFocus(src)
      return
    }

    setFocus(false)
  }

  const renderMedia = media => {
    return media.map(m => {
      switch (m.type) {
        case "image":
          return (
            <div
              className={`${styles.media} ${
                focused == m.src && styles.focused
              }`}
              onClick={() => {
                toggleFocus(m.src)
              }}
            >
              <img src={m.src} className={styles.image} loading="eager" />
              <div className={styles.des}>{m.des}</div>
            </div>
          )
        case "video":
          return (
            <div
              className={`${styles.media} ${
                focused == m.src && styles.focused
              }`}
              onClick={() => {
                toggleFocus(m.src)
              }}
            >
              <video controls>
                <source src={m.src} type="video/mp4" />
              </video>
              <div className={styles.des}>{m.des}</div>
            </div>
          )
          break
        case "twitter":
          return (
            <div
              className={`${styles.media} ${
                focused == m.src && styles.focused
              }`}
              onClick={() => {
                toggleFocus(m.src)
              }}
            >
              <div
                className={styles.twitter}
                dangerouslySetInnerHTML={{ __html: m.src }}
              ></div>

              <div className={styles.des}>{m.des}</div>
            </div>
          )
          break
        case "youtube":
          return (
            <div
              className={`${styles.media} ${styles.youtube} ${
                focused == m.src && styles.focused
              }`}
              onClick={() => {
                toggleFocus(m.src)
              }}
            >
              <div
                className={styles.twitter}
                dangerouslySetInnerHTML={{ __html: m.src }}
              ></div>

              <div className={styles.des}>{m.des}</div>
            </div>
          )
          break

        default:
          return null
      }
    })
  }

  const renderWork = () => {
    return WORK.map(w => {
      return (
        <div className={styles.item} id={w.name}>
          <div className={styles.timeline}>
            <div className={styles.end}>{w.end_year}</div>
            <div className={styles.line}></div>
            <div className={styles.start}>{w.start_year}</div>
          </div>
          <div className={styles.content}>
            <div className={styles.info}>
              <div className={styles.name}>
                {w.name}{" "}
                {w.gh_stars && (
                  <a
                    href={w.gh_link}
                    target="_blank"
                    className={styles.ghstars}
                  >
                    <div className={styles.stars}>
                      <GitHubLogo className={styles.icon} />
                      Star
                    </div>
                    <div className={styles.count}>{w.gh_stars}</div>
                  </a>
                )}
                {w.cta_text && (
                  <a href={w.cta_link} target="_blank" className={styles.cta}>
                    <div className={styles.count}>{w.cta_text}</div>
                  </a>
                )}
              </div>
              <div className={styles.des}>{w.des}</div>
            </div>
            <div className={styles.mediaList}>{renderMedia(w.media)}</div>
          </div>
        </div>
      )
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.highlight}>
          <div className={styles.title}>Highlights</div>
          I'm a multi-disciplinary maker and bioinformatician. I create
          products, do research, and design things with friends, startups and
          organizations. I believe in making things that bring the power and
          magic of innovation to more people.
          <br />
          <br />
          Learn more about my work at{" "}
          <a href="https://un.ms" target="_blank">
            UNMS
          </a>
          .
        </div>
      </div>
      <div className={styles.portfolio}>{renderWork()}</div>
    </div>
  )
}

export default WorkPage
